<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h5 class="text-center mb-5">Выбор членов аттестационной комиссии</h5>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyLevelId">
            <option v-for="(item, index) in adviserChoose_form.studyLevels" :key="index"
                    :value="item.id">
              {{item.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="studyLevelId">
        <label class="col-md-3 col-form-label">Образовательная программа</label>
        <div class="col-md-9" v-if="!loadingPrograms">
          <select class="form-control form-select" v-model="educationProgramId">
            <option v-for="(item, index) in graduateWorkAttestation.programs" :key="index"
                    :value="item.id">
              {{item.education_speciality_code}} {{item.education_speciality_name}} -
              {{item.language_name}} - {{item.admission_year}}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="educationProgramId">
        <div class="col-md-3">Группы</div>
        <div class="col-md-9" v-if="!loadingGroup">
          <div v-if="adviserChoose_form.studentGroups.length">
            <select class="form-control form-select" v-model="groupId">
              <option v-for="(item, index) in adviserChoose_form.studentGroups" :key="index" :value="item.id">
                {{item.name}}
              </option>
            </select>
          </div>
          <div v-else class="text-danger">
            В данной образовательной программе нет групп
          </div>
        </div>
      </div>
      <!-- end filter section -->


      <!-- select members section -->
      <div v-if="loadingGroupData" class="d-flex justify-content-center my-5">
        <Preloader/>
      </div>
      <div class="my-4" v-else-if="isGroupDataLoaded&&Object.keys(attestationMembers).length&&groupId">

        <h5 class="text-center mt-5 mb-4">Выбор членов аттестационной комиссии</h5>

        <div class="row my-4">
          <label class="col-md-3 col-form-label">Председатель</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="attestationMembers.chairman">
              <option v-for="(user, userIndex) in graduateWorkAttestation.users.chairmain" :key="userIndex"
                      :value="user.id">
                {{user.lastname}} {{user.firstname}} {{user.middlename}}
              </option>
            </select>
          </div>
        </div>

        <div class="row my-4">
          <label class="col-md-3 col-form-label">Комиссия 1</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="attestationMembers.commission_member_1">
              <option v-for="(user, userIndex) in graduateWorkAttestation.users.commission" :key="userIndex"
                      :value="user.user_id">
                {{user.lastname}} {{user.firstname}} {{user.middlename}}
              </option>
            </select>
          </div>
        </div>

        <div class="row my-4">
          <label class="col-md-3 col-form-label">Комиссия 2</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="attestationMembers.commission_member_2">
              <option v-for="(user, userIndex) in graduateWorkAttestation.users.commission" :key="userIndex"
                      :value="user.user_id">
                {{user.lastname}} {{user.firstname}} {{user.middlename}}
              </option>
            </select>
          </div>
        </div>


        <div class="row my-4">
          <label class="col-md-3 col-form-label">Тех. секретарь</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="attestationMembers.teh_secretary">
              <option v-for="(user, userIndex) in graduateWorkAttestation.users.graduateWorkTehSecretary" :key="userIndex"
                      :value="user.user_id">
                {{user.lastname}} {{user.firstname}} {{user.middlename}}
              </option>
            </select>
          </div>
        </div>


        <div class="my-4 text-center">
          <button type="button" class="btn btn-primary" @click="saveMembers" :disabled="btnSaveStatus&&!checkMembers">
            <span v-if="btnSaveStatus" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            {{btnSaveStatus ? 'Сохранение' : 'Сохранить'}}
          </button>
        </div>

      </div>
      <!-- end select members section -->


    </div>

  </div>

</template>


<script>

  import {mapActions, mapState} from 'vuex'

  export default {
    name: "SelectionMembersOfAttestationCommission",
    data() {
      return {
        loading: true,
        studyLevelId: 0,
        studyCourse: 0,
        educationProgramId: 0,
        groupId: 0,
        loadingGroup: false,
        loadingGroupData: false,
        isGroupDataLoaded: false,
        btnSaveStatus: false,
        users: [
          {
            id: 0,
            name: 'Вакансия'
          }
        ],
        attestationMembers: {}
      }
    },
    computed: {
      ...mapState('adviserChoose', ['adviserChoose_form']),
      ...mapState('graduateWorkAttestation', ['graduateWorkAttestation']),
      checkMembers() {
        return Object.values(this.attestationMembers).some(i => !i)
      }
    },
    watch: {
      studyCourse() {
        this.getEducationPrograms()
        this.isGroupDataLoaded = false
      },
      studyLevelId() {
        this.loadingPrograms = true
        this.getEducationPrograms()
        this.groupId = 0
        this.isGroupDataLoaded = false
        this.loadingPrograms = false
      },
      async educationProgramId(id) {
        this.loadingGroup = true
        await this.GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID(id)
        this.loadingGroup = false
        this.isGroupDataLoaded = false
      },
      async groupId(id) {
        await this.getGraduateWorkUsersByGroup(id)
      }
    },
    methods: {
      ...mapActions('adviserChoose', [
        'GET_STUDY_LEVELS', 'GET_EDUCATION_PROGRAMS',
        'GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID'
      ]),
      ...mapActions('graduateWorkAttestation', [
        'GET_GRADUATE_WORK_USERS_BY_GROUP',
        'POST_GRADUATE_WORK_USERS',
        'PUT_GRADUATE_WORK_USERS',
        'GET_TEACHERS',
        'GET_LAST_EDUCATION_PROGRAMS'
      ]),
      getEducationPrograms() {
        if (this.studyLevelId) {
          this.GET_LAST_EDUCATION_PROGRAMS(this.studyLevelId)
        }
      },
      async getGraduateWorkUsersByGroup(id) {
        this.loadingGroupData = true

        const res = await this.GET_GRADUATE_WORK_USERS_BY_GROUP(id)

        if (Object.keys(res).length) {
          this.attestationMembers = {
            id: res.id,
            student_group_id: res.student_group_id,
            chairman: res.chairman,
            commission_member_1: res.commission_member_1,
            commission_member_2: res.commission_member_2,
            teh_secretary: res.teh_secretary
          }
        } else {
          this.attestationMembers = {
            chairman: 0,
            commission_member_1: 0,
            commission_member_2: 0,
            teh_secretary: 0,
          }
        }

        this.isGroupDataLoaded = true
        this.loadingGroupData = false
      },
      async saveMembers() {
        this.btnSaveStatus = true

        let res = false

        if (this.attestationMembers.id) {
          res = await this.PUT_GRADUATE_WORK_USERS(this.attestationMembers)
        } else {
          this.attestationMembers.student_group_id = this.groupId
          res = await this.POST_GRADUATE_WORK_USERS(this.attestationMembers)
        }

        if (res) {
          await this.getGraduateWorkUsersByGroup(this.groupId)
          this.$message({text: 'Сохранено'})
        }

        this.btnSaveStatus = false
      },
    },
    async mounted() {
      await this.GET_STUDY_LEVELS()
      await this.GET_TEACHERS()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>